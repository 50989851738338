.lang-drop {
  cursor: pointer;
  font-size: 1.5em;
  color: #1a1a1a;

  &:hover {
    color: #000;
  }
}

.lang-select {
  font-size: 1.25em;
  color: #000;
  margin-right: 0;

  img {
    margin: 0;
  }
}

footer .lang-drop {
  font-size: 1em;
}
