.file-list {
  width: 100%;
  margin-top: 10px;
}

.file-list-header,
.file-list-item {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr 1fr; /* Adjust based on desired column sizes */
  padding: 10px;
}

.file-list-header {
  font-weight: bold;
  background: #000;
  color: #fff;
  border-bottom: 0;
  padding: 0.75em;
}

.file-list-item {
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: #e9e9e9;
  }
}

.file-list .file-list-item:last-of-type {
  border-bottom: 0;
}

.file-name-column {
  margin: 0;
  padding-left: 5px;
  display: flex;
  align-items: center;
  img {
    width: 25px;
    margin-right: 0.5em;
  }
}

.file-date-column,
.file-size-column {
  align-content: center;
  text-align: left;
}
.file-list-download {
  padding: 0.5em;
  font-size: 1.25em;
  border-radius: 50%;
  font-weight: 900;
}

.file-list-download:hover {
  color: #fff;
  background-color: #000;
  cursor: pointer;
}

.file-upload-container {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input::file-selector-button {
    color: #fff;
    background: #1a1a1a;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.65);
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
    border: 0;
  }
}

.google-upload-modal {
  .ant-modal-content {
    height: 50vh;
  }
}

.right-click-menu-drive {
  background: #1a1a1a;
  list-style-type: none;
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-right: 1.5em;
  position: absolute;
  z-index: 1;
  width: auto;

  li {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0.25em 0;
    padding: 0.7em 1.25em;
    color: rgba(255, 255, 255, 0.65);
    cursor: pointer;
    > :first-child {
      padding-right: 0.75em;
    }

    &:nth-child(3) {
      padding-bottom: 1.25em;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.65);
    }

    &:hover {
      color: #fff;
    }
  }
}
