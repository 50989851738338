.container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  margin: 0;
}

.header {
  display: flex;
  background-color: #1a1a1a;
  color: #fff;
  padding: 0.5em;
  font-weight: bold;
}

.row {
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-bottom: 1px solid #dedede;
  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: #e9e9e9;
  }
}

.docker-file-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-button-group {
  button:last-child {
    margin-left: 0.5em;
  }
}

.column {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  &.action-column {
    button:last-child {
      margin-left: 0.5em;
    }
  }
}

.floating-btn {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  margin-right: 2.5em;
  margin-bottom: 2.5em;
  border-radius: 50%;
  background: #000;
  filter: drop-shadow(0px 5px 5px rgba(28, 27, 31, 0.3));
  span {
    width: 5em;
    height: 5em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #fff;
      width: 2em;
      height: 2em;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
}

.right-click-menu-drive {
  background: #1a1a1a;
  list-style-type: none;
  margin-block-start: 0;
  padding-inline-start: 0;
  margin-right: 1.5em;
  position: absolute;
  z-index: 500;
  width: auto;
  height: auto;

  li {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0.25em 0;
    padding: 0.7em 1.25em;
    color: rgba(255, 255, 255, 0.65);
    cursor: pointer;
    > :first-child {
      padding-right: 0.75em;
    }

    &:nth-child(3) {
      padding-bottom: 1.25em;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.65);
    }

    &:hover {
      color: #fff;
    }
  }
}
