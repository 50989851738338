.login-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #1a1a1a;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  button {
    cursor: pointer;
    outline: none;
    border: 0;
    focus: none;
    margin: 0;
  }
}

.login-card {
  text-align: left;
  width: 80%;
  height: 80%;
  display: flex;
  background: #e9e8e8;
}

.login-card-left,
.login-card-right {
  flex: 1;
  flex-grow: 1;
}

.login-card-left {
  background: url("../images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 40%;
}

.login-card-right {
  &.provider-signup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &.initialize {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  padding: 1em 2em;
  overflow: scroll;
  .right-side-top {
    text-align: right;
    color: #9d9698;
    font-size: 0.75em;
    font-weight: 600;
  }

  .right-side-initialize-top,
  .right-side-middle {
    text-align: left;
    margin: 1em 0;

    h1,
    h3 {
      margin: 0;
    }

    h1 {
      color: #1a1a1a;
    }

    h3 {
      color: #9d9698;
      font-size: 1em;
      font-weight: 400;
      letter-spacing: 1.5;
    }
  }

  .right-side-bottom {
    color: #9d9698;

    footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      span {
        font-size: 12px;
      }
    }
  }

  .sign-up-btn {
    padding: 0.75em;
    background: #fff;
    border: 0;
    color: #1a1a1a;
    border-radius: 100px;
  }
}

.form {
  margin: 2em 0;

  .label-wrapper {
    margin: 1em 0;

    label {
      margin: 0.5em 0;
      font-weight: 600;
      display: block;
    }

    input {
      color: #1a1a1a;
      background-color: rgba(255, 255, 255, 0.65);
      font-size: 1em;
      padding: 0.5em;
      width: 85%;
      border: 1px solid #d6d3d4;
      border-radius: 8px;
      outline: none;
    }
  }

  .sign-up-btn,
  .sign-in-btn {
    margin: 1em 0;
    padding: 1em;
    background: #0f0f0f;
    color: #fff;
    border-radius: 100px;
    filter: drop-shadow(0 0 0.15rem #0f0f0f);
    width: 45%;
  }

  .sign-up-btn {
    width: 100%;
  }

  .form-error-msg {
    margin: 0.5em 0;
    color: #ff0000;
  }
}

@media only screen and (max-width: 800px) {
  .login-card {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .login-card-left {
    display: none;
  }

  .login-card {
    width: 100%;
    height: 100%;
  }

  .form .sign-in-btn {
    width: 88%;
  }
}
