.installation-info {
  h4 {
    a {
      color: #1a1a1a;
      text-decoration: underline;
      &:hover {
        color: #000;
        text-decoration: none;
      }
    }
  }
  p {
    margin: 1em 0;
  }

  code {
    display: block;
    margin: 1em 0;
    background: #eee;
    color: #1e1e1e;
  }
}
