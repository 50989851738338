.ant-spin-text {
  margin-top: 0.5em;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.instance-detail-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.instance-loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.instance-detail-card {
  padding: 1em;
  height: auto;
  display: flex;
  flex-direction: column;

  .instance-detail-card-details {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin: 0;

    li {
      margin: 0.25em 0;
      padding: 0.25em 0;
      color: #9d9698;
    }

    li.new-tag {
      position: absolute;
      right: 0;
      margin: 0 1em;
      background: #ff1b1b;
      color: #fff;
      font-weight: 700;
      border-radius: 4px;
      padding: 0.325em 0.5em;
      text-transform: uppercase;
    }
  }
}

.options-container {
  display: flex;
  flex-direction: column;

  button,
  a {
    margin-bottom: 0.75em;
  }
}

.add-key-form {
  display: flex;
  flex-direction: column;

  textarea {
    height: 100px;
  }

  .add-key-btn {
    margin: 1em 0;
    padding: 0.75em 1.25em;
    background: #0f0f0f;
    color: #fff;
    border-radius: 8px;
    border: 0;

    &:disabled {
      background: #9d9698;
      color: #fff;
    }
  }
}
