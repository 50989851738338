.template-sorting {
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  input {
    width: auto;
    margin-right: 1em;
  }
}

.template-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.template-card {
  height: auto;
  background: #fff;
  border-radius: 12px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  height: 175px;
  justify-content: space-between;

  &.fake {
    background: rgba(255, 255, 255, 0.75);
  }

  filter: drop-shadow(0px 1px 1px rgba(28, 27, 31, 0.05));

  .template-card-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .template-card-image {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    height: 85px;
    -webkit-box-pack: center;
    justify-content: center;
    width: 80px;
    max-width: 25%;
    margin-right: 1em;
    img {
      max-width: 100px;
      width: 100%;
    }
  }

  .template-card-specs {
    width: 100%;
    * {
      margin: 0.25em 0;
    }

    h3 {
      margin: 0.25em 0;
      padding: 0;
      color: #091841;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      gap: 10px;
      text-transform: capitalize;
    }

    span {
      color: #666666;
      font-size: 0.75em;
      font-weight: 300;
    }
  }

  .template-card-details {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;

    li {
      margin: 0.25em 0;
      padding: 0.25em 0;
      color: #9d9698;
    }
  }
}

@media only screen and (max-width: 2000px) {
  .template-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  .template-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .template-sorting {
    flex-direction: column;
    align-items: flex-start;

    input {
      width: 100%;
      margin-bottom: 1em;
      margin-right: 0;
    }

    .ant-radio-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .template-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
