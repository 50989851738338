.no-instances {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.file-cloud-download {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  .instance-item {
    padding: 1em;
    cursor: pointer;

    &:hover {
      background: #eeeded;
      border: 1px solid rgba(28, 27, 31, 0.13);
    }
  }
}
