.user-details {
  display: flex;
  margin-bottom: 0.5em;

  img {
    margin-right: 0.75em;
  }
}

.charts-wrapper {
  display: flex;
  flex-direction: row;
  > div {
    width: 50%;
  }
}

@media (max-width: 1400px) {
  .charts-wrapper {
    > div {
      width: 50%;
    }
  }
}

@media (max-width: 1000px) {
  .charts-wrapper {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}
