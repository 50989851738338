.payment-table {
  display: flex;
  flex-direction: column;
}
.payment-table-row {
  display: flex;
}
.payment-table-header,
.payment-table-cell {
  flex: 1;
  padding: 0.25em;
}

.payment-table-row,
.payment-table-header {
  padding: 0.25em 0;

  .payment-table-cell {
    &:first-child {
      padding-left: 0;
      text-align: left;
    }
    text-align: right;
  }
}

.payment-table-header {
  .payment-table-cell {
    &:first-child {
      color: #000;
      font-weight: 500;
      font-size: 18px;
    }
    font-weight: 600;
    color: #000;
    text-align: right;
  }
}

.payment-table-row {
  .payment-table-cell {
    h3 {
      margin: 0;
      color: #000;
    }
  }
}

.payment-table-cell {
  color: #222;

  b {
    font-weight: 400;
    font-size: 0.75em;
  }
}

.card-wrapper {
  width: 80px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: space-between;

  img {
    height: 30px;
    width: auto;
  }
}

.payment-method {
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    margin-bottom: 0.75em;
  }

  .ant-radio-wrapper {
    align-items: center;
  }
}

.payment-method-bank {
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    vertical-align: middle;
  }

  .ant-radio-wrapper {
    margin-bottom: 0.75em;
  }
}
.total-cal-chart ul,
.product-details {
  list-style-type: none;
  margin-block-start: 0;
  padding-inline-start: 0;

  .speed-wrapper {
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    justify-items: center;

    .speed-title {
      display: flex;
      align-items: center;
    }

    .speed {
      display: flex;
      flex-direction: column;
      margin: 0 0.15em;
    }
  }

  li {
    margin: 0.25em 0;
    padding: 0.25em 0;
    color: #9d9698;
  }
}

.rental-details,
.payment-total {
  display: flex;
  flex-direction: column;

  .total {
    text-align: right;
    margin: 1em 0;
  }

  .cost-title {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1em;
    margin-right: 1em;
  }

  .total-price {
    color: #1a1a1a;
    font-size: 2em;
    font-weight: 700;
  }

  .total-cal-chart {
    display: flex;
    justify-content: flex-end;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;
    }
  }

  .ant-btn-primary:disabled {
    border: 0;
  }
}

.rental-details {
  .rent {
    .ant-progress .ant-progress-inner {
      * {
        height: 20px !important;
      }

      height: 20px;
    }

    p {
      text-align: center;
      color: black;
      font-weight: 600;
      letter-spacing: 4px;
    }
  }
}

// antd items

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #ffffff;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #ffffff;
}
