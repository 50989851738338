.wrapper {
  margin-bottom: 1em;
  filter: drop-shadow(0px 1px 1px rgba(28, 27, 31, 0.1));
}

.earnings-items {
  display: flex;
  vertical-align: top;
  justify-content: flex-start;

  .earnings-referrals {
    width: 50%;
  }

  .earnings-payout {
    width: 50%;
  }

  .earnings-details {
    margin-right: 1.5em;
  }

  .earnings-details,
  .payout-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .earnings-details-list {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin-right: 1.5em;

    li {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0.25em 0;
      padding: 0.25em 0;
      color: #9d9698;
    }

    .amount {
      font-weight: 700;
    }
  }

  .earnings-payout {
  }
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.ant-table-cell {
  a {
    color: #111111;
  }
}

@media only screen and (max-width: 1024px) {
  .earnings-items {
    display: flex;
    flex-direction: column;

    .earnings-details {
      margin-right: 0;
    }

    .earnings-details-list {
      margin-right: 0;
      li {
        width: auto;
      }
    }

    .earnings-referrals {
      width: 100%;
    }

    .earnings-payout {
      width: 100%;
    }
  }
}
