.wrapper {
  margin-bottom: 1em;
  filter: drop-shadow(0px 1px 1px rgba(28, 27, 31, 0.1));
}

.cards-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 0px;
}
.ant-table-cell {
  a {
    color: #111111;
  }
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
.table-striped-rows thead {
  background-color: #f5f5f5;
}
