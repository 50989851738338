.rental-sorting {
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  .search-bar {
    margin-right: 1em;
    input {
      width: auto;
    }
  }

  .slider-wrapper {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
  }

  .slider {
    &:last-child {
      margin-right: 0;
    }
    width: 29%;
    margin-right: 4.5em;

    .ant-slider-mark-text {
      width: 70px;
    }
  }
}
.rental-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.rental-card,
.rental-card-modal {
  height: auto;
  background: #fff;
  border-radius: 12px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 1px 1px rgba(28, 27, 31, 0.1));
  position: relative;

  .rental-card-specs {
    * {
      margin: 0.25em 0;
    }

    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        z-index: 999;
        position: absolute;
        right: 5px;
        top: 1px;
      }
    }

    h3 {
      margin: 0.25em 0;
      padding: 0;
      color: #1a1a1a;
      font-size: 1.25em;
      font-weight: 300;
    }

    .price {
      color: #1a1a1a;
      font-size: 1.5em;
      font-weight: 300;

      span {
        color: #666666;
        font-size: 0.75em;
        font-weight: 300;
      }
    }
  }

  .rental-card-details {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;

    .speed-wrapper {
      display: flex;
      vertical-align: middle;
      flex-direction: row;
      justify-items: center;

      .speed-title {
        display: flex;
        align-items: center;
      }

      .speed {
        display: flex;
        flex-direction: column;
        margin: 0 0.15em;
      }
    }

    li {
      margin: 0.25em 0;
      padding: 0.25em 0;
      color: #9d9698;
    }
  }
}

.rental-card-modal {
  background: transparent;
}
@media only screen and (max-width: 2000px) {
  .rental-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  .rental-sorting {
    flex-direction: column;
    align-items: flex-start;

    .search-bar {
      margin: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      input {
        width: 100%;
        margin-bottom: 1em;
        margin-right: 0;
      }
    }

    .slider-wrapper {
      width: 100%;
      padding: 1em;
      display: flex;
      flex-direction: column;
    }

    .slider {
      width: 100%;
      &:last-child {
        margin: 0;
      }
      .ant-slider-mark-text {
        max-width: 100%;
        width: auto;
      }
    }
  }
  .rental-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .rental-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
