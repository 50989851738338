.instance-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.instance-card {
  padding: 1em;
  height: auto;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 1px 1px rgba(28, 27, 31, 0.1));
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 1px 1px rgba(28, 27, 31, 0.3));
  }

  .instance-card-details {
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin: 0;

    li {
      margin: 0.25em 0;
      padding: 0.25em 0;
      color: #9d9698;
    }

    li.new-tag {
      position: absolute;
      right: 0;
      margin: 0 1em;
      background: #ff1b1b;
      color: #fff;
      font-weight: 700;
      border-radius: 4px;
      padding: 0.325em 0.5em;
      text-transform: uppercase;
    }
  }
}

.instance-card-none {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 1em;
  filter: drop-shadow(0px 1px 1px rgba(28, 27, 31, 0.1));
}
.breadcrumb {
  margin: 0.75em;
  .breadcrumb-item {
    cursor: pointer;
    font-size: 1.25em;
    font-weight: 600;
    padding: 0.25em;
    border-radius: 4px;

    &:hover {
      background-color: rgba(28, 27, 31, 0.1);
    }
  }
}

.view-more-bar {
  cursor: pointer;
  border-radius: 8px;
  color: #fff;
  background-color: #1a1a1a;
  width: 100%;
  padding: 1em 0;
  text-align: center;
  &:hover {
    background-color: rgba(28, 27, 31, 0.95);
  }
}

@media only screen and (max-width: 2000px) {
  .instance-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  .instance-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .instance-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
