.logo {
  height: 52px;
  width: 116px;
  margin-left: 1.5em;
  margin-top: 1em;
  background-image: url("assets/images/white-feat-logo.svg");
}

.logo-black {
  height: 60px;
  width: 116px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  opacity: 0.65;
  background-image: url("assets/images/black-feat-logo.svg");
}

.layout {
  margin-left: 200px;
  min-height: 100vh;
  height: auto;
}

.content {
  margin: 24px 16px 0px;
  overflow: initial;
}

.logo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mobile-menu {
    display: none;
    padding: 1em;
    color: #fff;
    cursor: pointer;
  }
}

.header-wrapper {
  background: #fff;
  display: flex;
  align-items: center;
  line-height: normal;
  height: auto;
  padding: 1em;
  justify-content: flex-end;
}

.main-menu {
  aside {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    flex: 0 0 200px;
    max-width: 200px;
    min-width: 200px;
    width: 200px;
    min-width: 0;
    background: #1a1a1a;
    transition: all 0.2s, background 0s;
  }
}

.main-menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 64px);
}

// commonly used css

.wrapper {
  padding: 24px;
  background: #fff;
  border-radius: 12px;
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: middle;
  justify-content: center;
}

.tutorial-btn {
  margin-right: 0.5em;
  a {
    color: #1a1a1a;
    font-weight: 500;
  }
}

@media (max-width: 860px) {
  .ant-layout.ant-layout-has-sider.main-wrapper {
    flex-direction: column;
  }

  .layout {
    margin: 0;
  }

  .logo-wrapper {
    .mobile-menu {
      display: block;
    }
  }

  .main-menu {
    aside {
      height: auto;
      position: relative;
      width: 100%;
      max-width: 100%;
    }

    .logo {
      margin-bottom: 1em;
    }
  }

  .main-menu-wrapper {
    display: none;
  }

  .show-mobile {
    display: flex;
  }
}
